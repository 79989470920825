import './component.scss'
import './images/top_search_client_building.svg'
import './images/top_search_client_outer_wall.svg'
import './images/top_search_client_mappin.svg'
import './images/top_search_client_paint.svg'
import './images/top_search_client_part_exterior_outer_wall.svg'
import './images/top_search_client_part_exterior_roof.svg'
import './images/top_search_client_nurikaeru.svg'
import './images/top_search_client_circle_arrow_right.svg'
import TopSearchClientController from './controllers/top_search_client_controller'

window.Stimulus.register('top-search-client', TopSearchClientController)
