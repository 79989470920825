import { Controller } from 'stimulus'
import '@stimulus/polyfills'

const CONDITION_BUTTON_SELECTED_CLASS = 'top-search-client__condition-button--selected'
const LINK_CONTAINER_HIDE_CLASS = 'top-search-client__link-container--hide'
const REGION_BUTTONS_SELECTOR = '.js-top-search-client-region-buttons'
const REGION_BUTTONS_HIDE_CLASS = 'top-search-client__region-buttons--hide'
const PREFECTURE_BY_REGION_SELECTOR = '.js-top-search-client-prefecture-by-region'
const PREFECTURE_BY_REGION_SHOW_CLASS = 'top-search-client__prefecture-by-region--show'
const LINK_CONTAINER_SELECTOR = '.js-top-search-client-link-container'

export default class extends Controller {
  static targets = ['exteriorOuterWall', 'exteriorRoof', 'partType']

  changeCondition (e) {
    const siblings = Array.from(e.currentTarget.parentNode.children).filter(child => child !== e.currentTarget)
    siblings.forEach(sibling => sibling.classList.remove(CONDITION_BUTTON_SELECTED_CLASS))
    e.currentTarget.classList.add(CONDITION_BUTTON_SELECTED_CLASS)

    this.hideAllConditionTargets()

    const condition = e.currentTarget.dataset.condition;
    this.showConditionTarget(condition);
  }

  hideAllConditionTargets () {
    this.exteriorOuterWallTarget.classList.add(LINK_CONTAINER_HIDE_CLASS);
    this.exteriorRoofTarget.classList.add(LINK_CONTAINER_HIDE_CLASS);
    this.partTypeTarget.classList.add(LINK_CONTAINER_HIDE_CLASS);
  }

  showConditionTarget (condition) {
    switch (condition) {
      case 'outerWall':
        this.exteriorOuterWallTarget.classList.remove(LINK_CONTAINER_HIDE_CLASS);
        break;
      case 'roof':
        this.exteriorRoofTarget.classList.remove(LINK_CONTAINER_HIDE_CLASS);
        break;
      case 'partType':
        this.partTypeTarget.classList.remove(LINK_CONTAINER_HIDE_CLASS);
        break;
      default:
        console.warn(`Unknown condition: ${condition}`);
    }
  }

  showPrefectureByRegion (e) {
    const regionButtons = e.currentTarget.closest(LINK_CONTAINER_SELECTOR).querySelector(REGION_BUTTONS_SELECTOR)
    regionButtons.classList.add(REGION_BUTTONS_HIDE_CLASS)

    const regionId = e.currentTarget.dataset.regionId
    const partTypeId = e.currentTarget.dataset.partTypeId
    const prefectureByRegion = document.querySelector(`${PREFECTURE_BY_REGION_SELECTOR}[data-region-id="${regionId}"][data-part-type-id="${partTypeId}"]`)
    prefectureByRegion.classList.add(PREFECTURE_BY_REGION_SHOW_CLASS)
  }

  showRegionButtons (e) {
    const regionButtons = e.currentTarget.closest(LINK_CONTAINER_SELECTOR).querySelector(REGION_BUTTONS_SELECTOR)
    regionButtons.classList.remove(REGION_BUTTONS_HIDE_CLASS)

    const visiblePrefectureByRegion =  e.currentTarget.closest(`.${PREFECTURE_BY_REGION_SHOW_CLASS}`)
    visiblePrefectureByRegion.classList.remove(PREFECTURE_BY_REGION_SHOW_CLASS)
  }
}
